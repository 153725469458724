<template>
  <div class="order-common">
    <b-modal
      id="md-setting-user"
      hide-footer
      title="Cấu hình nhân viên:"
      no-close-on-backdrop
      size="lg"
    >
      <b-row class="mb-2">
        <b-col cols="4">
          <vue-autosuggest
            v-model="apiParams.employeeName"
            :suggestions="optionsEmployee"
            :limit="100"
            @selected="onSelected($event, 'Employee')"
            @input="onInputed($event, 'Employee')"
            :input-props="{
              class: 'autosuggest__input',
              placeholder: 'Nhập nhân viên CSKH',
            }"
            :should-render-suggestions="
              (size, loading) => size >= 0 && !loading
            "
            :get-suggestion-value="({ item }) => item.fullName"
          >
            <template slot-scope="{ suggestion }">
              {{ suggestion.item.fullName }}
            </template>
          </vue-autosuggest>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-table
            :items="employees"
            :fields="employeesFields"
            :small="true"
            responsive
            bordered
            hover
          >
            <template #cell(code)="data">
              <b class="text-primary">{{ data.item.code }}</b>
            </template>
            <template #cell(actions)="data">
              <div v-if="data.item.reportDeleted === 1">
                <div @click="removeEmployess(data.item.id, 0)">
                  <span>
                    <i class="fa fa-recycle text-warning" aria-hidden="true">
                    </i>
                    Hoàn tác
                  </span>
                </div>
              </div>
              <div v-else>
                <div @click="removeEmployess(data.item.id, 1)">
                  <span>
                    <i
                      style="font-size: 1rem"
                      class="fa fa-trash text-danger"
                      aria-hidden="true"
                    >
                    </i>
                    Xoá
                  </span>
                </div>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-overlay
        :show="isBusy"
        rounded
        opacity="0.6"
        class="d-inline-block mr-3"
      >
        <template #overlay>
          <div class="d-flex align-items-center">
            <b-spinner variant="light" small type="grow"></b-spinner>
          </div>
        </template>
        <b-button
          :disabled="isBusy"
          variant="primary"
          size="sm"
          @click="onSaveUserReport"
        >
          <strong>Lưu</strong>
        </b-button>
      </b-overlay>

      <b-button
        style="width: 80px"
        variant="secondary"
        size="sm"
        @click="$bvModal.hide('md-setting-user')"
      >
        <strong>Hủy</strong>
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { cloneDeep, removeAccents } from '@/utils/common';
import { makeToastSuccess } from '../../../../utils/common';
export default {
  props: {
    report: Object,
  },
  components: {},
  data() {
    return {
      isBusy: false,
      apiParams: {
        employeeName: null,
        employeeId: null,
      },
      filteredEmployees: [],
      optionOrigin: {
        employees: [],
      },
      employees: [],
      employeesFields: [
        {
          key: 'code',
          label: 'Mã nhân viên',
          thClass: 'text-center',
          thStyle: {
            width: '5%',
          },
        },
        {
          key: 'fullName',
          label: 'Tên nhân viên',
          thStyle: {
            width: '9%',
          },
        },
        {
          key: 'actions',
          label: ' ',
          tdClass: 'text-center',
          thStyle: {
            width: '5%',
          },
        },
      ],
    };
  },
  watch: {
    report: {
      handler(value) {
        if (value) {
          this.getReportEmployees(value.id);
        }
      },
      deep: true,
    },
  },
  created() {
    this.fetchEmployees();
  },
  mounted() {},
  beforeDestroy() {},
  computed: {
    optionsEmployee() {
      return [{ data: cloneDeep(this.filteredEmployees) }];
    },
  },
  methods: {
    onSaveUserReport() {
      const params = {
        reportId: this.report.id,
        employees: this.employees,
      };
      ApiService.put('power-bi/report/employee', params).then(() => {
        makeToastSuccess('Thành công');
      });
    },
    onInputed(textInput = '', type) {
      switch (type) {
        case 'Employee':
          this.filteredEmployees = this.fitlerOptionsBy(
            this.optionOrigin.employees,
            textInput,
            ['fullName'],
            10
          );
          console.log(' this.filteredEmployees', this.filteredEmployees);
          break;
        default:
          break;
      }
    },
    onSelected({ item }, type) {
      switch (type) {
        case 'Employee':
          this.addEmployee(item.id);
          break;
        default:
          break;
      }
    },
    addEmployee(id) {
      const employees = this.optionOrigin.employees;
      const employee = employees.find((emp) => emp.id === id);
      if (employee) {
        this.employees.push(employee);
      }
    },
    removeEmployess(id, status) {
      const employees = this.optionOrigin.employees;
      const employeesIndex = employees.findIndex((emp) => emp.id === id);
      console.log('employeesIndex', employeesIndex);
      if (employeesIndex > -1) {
        const employeeModify = this.employees[employeesIndex];
        employeeModify.reportDeleted = status;
      }
    },
    getReportEmployees(reportId) {
      this.employees = [];
      const params = {
        reportId,
      };
      ApiService.query('power-bi/report/employees', { params }).then(
        (response) => {
          const { data } = response.data;
          this.employees = data;
        }
      );
    },
    fetchEmployees: function () {
      ApiService.get('employees/getAll').then((response) => {
        if (response.data.status) {
          const employees = response.data.data;

          const employeeeMapper = employees.map((employee) => {
            return {
              ...employee,
              reportDeleted: 0,
            };
          });

          this.filteredEmployees = employeeeMapper;
          this.optionOrigin.employees = employeeeMapper;
        }
      });
    },
    fitlerOptionsBy(items, textInput, props, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameConcatString = (props || []).reduce((result, prop) => {
              return (result += item[prop] + ' ');
            }, '');

            const nameWTUnicode = removeAccents(nameConcatString).toLowerCase();
            const nameInputWTUnicode = removeAccents(textInput).toLowerCase();
            const index = nameWTUnicode.indexOf(nameInputWTUnicode);

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
  },
};
</script>
<style>
.autosuggest__results {
  position: absolute;
  width: 100%;
  max-height: 200px;
  z-index: 999;
  border: 1px solid #e0e0e0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.35em + 1.1rem + 2px);
  line-height: 1.35;
  padding: 0.55rem 0.75rem;
  font-weight: 400;
  font-size: 0.925rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.28rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
#autosuggest {
  width: 100%;
}
</style>
